<template>
    <button class="w-full group flex justify-center items-center gap-x-3 rounded-md py-2 text-sm font-semibold leading-6 text-white bg-green-500 hover:bg-green-600"
            @click="contactSupport">
        <icon class="shrink-0" name="logos:whatsapp-icon" size="28"/>

        Support
    </button>
</template>

<script setup>
const {$global} = useNuxtApp()

function contactSupport() {
    const whats_app_text = encodeURIComponent(`Përshëndetje, `)

    window.open(`https://wa.me/${$global.contact_whatsapp}?text=${whats_app_text}`, '_blank')
}
</script>