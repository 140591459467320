<template>
    <section v-if="user"
             class="block md:hidden fixed inset-x-0 bottom-0 z-10 border-t border-b shadow bg-white text-gray-900">
        <div class="flex justify-between py-2 text-center text-sm">
            <nuxt-link class="w-full flex flex-col justify-center items-center py-2" to="/listings">
                <icon class="inline-block text-gray-400" name="material-symbols:home-work-rounded" size="22"/>

                {{ $t('listings') }} ({{ user.listings_count }})
            </nuxt-link>

            <button class="w-full flex flex-col justify-center items-center py-2"
                       @click="redirect('create')">
                <icon class="inline-block text-gray-400" name="material-symbols:add-home-work-rounded" size="22"/>

                {{ $t('create') }}
            </button>

            <button class="w-full flex flex-col justify-center items-center py-2"
                       @click="redirect('import')">
                <icon class="inline-block text-gray-400" name="fa6-solid:file-import" size="22"/>

                {{ $t('import_listing') }}
            </button>
        </div>
    </section>
</template>

<script setup>
import {useAuthStore} from "~/stores/auth";

const user = computed(() => useAuthStore().user)
const router = useRouter()
const {$bus} = useNuxtApp()
const {t} = useI18n()

function redirect(route) {
    if (user.value.connections_count > 0)
        return router.push(`/${route}`)

    $bus.$emit('flash', {
        type: 'error',
        title: t('connect_at_least_one_platform'),
    })

    router.push('/connect')
}
</script>
