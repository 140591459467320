<template>
  <div>
    <navigation-mobile-top/>

    <navigation-mobile-bottom/>

    <navigation-sidebar/>

    <div class="md:pl-64">
      <!--          <div class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                  <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
                    <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
                  </div>
                  <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
                    <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
                  </div>
                  <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
                    <p class="text-sm leading-6 text-gray-900">
                      <strong class="font-semibold">GeneriCon 2023</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Join us in Denver from June 7 – 9 to see what’s coming next.
                    </p>
                    <a href="#" class="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Register now <span aria-hidden="true">&rarr;</span></a>
                  </div>
                  <div class="flex flex-1 justify-end">
                    <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                      <span class="sr-only">Dismiss</span>
                      <svg class="h-5 w-5 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                      </svg>
                    </button>
                  </div>
                </div>-->

      <navigation-dashboard-top-nav/>

      <main class="flex-1">
        <tools-notification/>

        <div class="py-14">
          <div class="mx-auto max-w-7xl px-0 sm:px-6 md:px-8">
            <slot/>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import {useInfoStore} from "~/stores/info";

const {$global} = useNuxtApp()

onMounted(() => {
  $fetch(`${$global.api_url}/api/agent/info`, {
    headers: {
      'Authorization': 'Bearer ' + useCookie('token').value
    }
  })
      .then(response => {
        useInfoStore().setInfo(response.data)
      })
});
</script>

<style>
html {
  @apply bg-gray-50
}

.link {
  @apply text-indigo-500 hover:text-indigo-700 cursor-pointer;
}

.for-mobile {
  @apply px-3 sm:px-6
}

h1 {
  @apply text-2xl sm:text-3xl font-bold tracking-tight text-gray-900
}

h2 {
  @apply text-xl font-bold tracking-tight text-gray-900
}

h3 {
  @apply text-lg tracking-tight text-gray-900 font-semibold
}

h4 {
  @apply tracking-tight text-gray-900
}

p {
  @apply text-gray-700
}

.input {
  @apply appearance-none block w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm placeholder-gray-400 sm:text-sm
}

.button-primary {
  @apply py-3 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700
}
</style>