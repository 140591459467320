<template>
  <div>
    <div v-if="show_profile" id="profile-menu-overlay"
         class="md:hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <button @click="show_profile = !show_profile">
      <icon class="inline-block text-gray-400" name="gg:profile" size="30"/>
    </button>

    <div
        v-if="show_profile"
        class="md:hidden fixed z-40 w-full overflow-y-auto bg-white border-t shadow-md rounded-t-lg transition-transform bottom-0 left-0 right-0 top-36 transform-none"
        tabindex="-1">

      <div class="p-4 mt-3">
                <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2"
                      @click="show_profile = false"></span>

        <span class="inline-flex items-center text-base text-gray-500 mt-3">
                  {{ $t('general.hi') }} {{ user.name }} 🤗
                </span>
      </div>

      <div class="grid grid-cols-3 gap-4 p-4 lg:grid-cols-4">
        <nuxt-link v-for="menu_item in menu_items"
                   :class="$route.path === menu_item.to ? 'bg-indigo-500' : 'bg-gray-50'"
                   :to="menu_item.to"
                   class="p-4 rounded-lg">
          <div
              class="flex justify-center items-center p-2 mx-auto mb-2 max-w-[48px] bg-white rounded-full w-18 h-18">
            <icon :class="{'text-dark': $route.path === menu_item.to}" :name="menu_item.icon"
                  class="inline w-8 h-8 fill-current"/>
          </div>

          <div :class="$route.path === menu_item.to ? 'text-white' : 'text-gray-700'"
               class="text-center">
            {{ $t(menu_item.text) }}
          </div>
        </nuxt-link>

        <nuxt-link class="p-4 rounded-lg bg-gray-50" to="#" @click.native="logout">
          <div
              class="flex justify-center items-center p-2 mx-auto mb-2 max-w-[48px] bg-white rounded-full w-18 h-18">
            <icon name="octicon:sign-out-16" size="26"/>
          </div>

          <div class="text-center text-gray-700">
            {{ $t('general.logout') }}
          </div>
        </nuxt-link>
      </div>

      <div class="mt-auto space-y-6 p-4">
<!--        <actions-trial-alert-action/>-->

        <actions-support-button/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "~/stores/auth";
import {addClosable, closeClosable} from "~/helpers/closable";

const route = useRoute()
const router = useRouter()
const {$global} = useNuxtApp()
const store = useAuthStore()

let user = computed(() => store.user)

const menu_items = ref([
  {
    to: '/connect',
    text: 'general.connect',
    icon: 'mingcute:plugin-fill',
  },

  /*{
    to: '/design-templates',
    text: 'Design templates',
    icon: 'material-symbols:design-services',
  },*/

  {
    to: '/profile',
    text: 'general.profile',
    icon: 'gg:profile',
  },

  {
    to: '/billing',
    text: 'billing',
    icon: 'streamline:money-cash-coins-stack-accounting-billing-payment-stack-cash-coins-currency-money-finance',
  },

  {
    to: `/u/${user.value ? user.value.username : ''}`,
    text: 'Portfolio',
    icon: 'bytesize:portfolio',
  },

  {
    to: '/errors',
    text: 'Errors',
    icon: 'solar:minimalistic-magnifer-bug-line-duotone',
  },

  {
    to: '/blog',
    text: 'blog',
    icon: 'carbon:blog',
  },
])

const show_profile = ref(false)

onMounted(() => addClosable(close))

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden')

  closeClosable(close)
})

function close(e) {
  if (document.getElementById('profile-menu-overlay') === e.target) {
    show_profile.value = false
  }
}

function logout() {
  $fetch(`${$global.api_url}/api/auth/logout`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + useCookie('token').value
    },
  }).then(async () => {
    await router.push('/')

    window.location.reload()
  })
}

watch(() => route.fullPath, () => show_profile.value = false)

watch(show_profile, (val) => {
  if (val === true) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
})
</script>
