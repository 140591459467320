<template>
  <div v-if="user" class="hidden w-48 md:fixed md:inset-y-0 md:z-50 md:flex md:w-64 md:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <nuxt-link class="flex h-16 shrink-0 items-center border-b space-x-3" to="/listings">
        <img v-img-error :alt="`${$global.name} logo`" class="h-14" src="/logo1.png"/>

        <span class="text-gray-700 font-bold pr-1">
          &
        </span>

        <img v-img-error :alt="`${$global.name} logo`" class="h-10 rounded-xl" src="/logo2.png"/>
      </nuxt-link>

      <nav class="flex flex-1 flex-col">
        <ul class="flex flex-1 flex-col gap-y-7" role="list">
          <li>
            <ul class="-mx-2 space-y-1" role="list">
              <li>
                <nuxt-link
                    :class="$route.name === 'listings' ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:text-white hover:bg-indigo-600'"
                    class="group flex gap-x-3 rounded-lg p-3 text-sm"
                    to="/listings">
                  <icon :class="$route.name === 'listings' ? 'text-white' : 'text-gray-800'"
                        class="group-hover:text-white mr-3" name="material-symbols:home-work-rounded"
                        size="22"/>

                  {{ $t('listings') }}<!-- ({{ user.listings_count }})-->
                </nuxt-link>
              </li>

              <li>
                <nuxt-link
                    :class="$route.name === 'connect' ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:text-white hover:bg-indigo-600'"
                    class="group flex gap-x-3 rounded-lg p-3 text-sm"
                    to="/connect">
                  <icon :class="$route.name === 'connect' ? 'text-white' : 'text-gray-800'"
                        class="group-hover:text-white mr-3"
                        name="mingcute:plugin-fill"
                        size="22"/>

                  <!--                  Connect ({{ user.connections_count }} / {{ info?.supported_platforms.length }})-->
                  {{ $t('general.connect') }} <!--({{ user.connections_count }} / 9)-->
                </nuxt-link>
              </li>

              <li>
                <button
                    id="create-url"
                    :class="$route.name === 'create-id' ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:text-white hover:bg-indigo-600'"
                    class="group flex gap-x-3 rounded-lg p-3 text-sm w-full"
                    @click="redirect('create')">
                  <icon :class="$route.name === 'create-id' ? 'text-white' : 'text-gray-800'"
                        class="group-hover:text-white mr-3"
                        name="material-symbols:add-home-work-rounded"
                        size="22"/>

                  {{ $t('create') }}
                </button>
              </li>

              <li>
                <button
                    :class="$route.name === 'import' ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:text-white hover:bg-indigo-600'"
                    class="group flex gap-x-3 rounded-lg p-3 text-sm w-full"
                    @click="redirect('import')">
                  <icon :class="$route.name === 'import' ? 'text-white' : 'text-gray-800'"
                        class="group-hover:text-white mr-3"
                        name="fa6-solid:file-import"
                        size="22"/>

                  {{ $t('import_listing') }}
                </button>
              </li>

              <!--              <li>
                              <button
                                  :class="$route.name === 'design-templates' ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:text-white hover:bg-indigo-600'"
                                  class="group flex gap-x-3 rounded-lg p-3 text-sm w-full"
                                  @click="redirect('design-templates')">
                                <icon :class="$route.name === 'design-templates' ? 'text-white' : 'text-gray-800'"
                                      class="group-hover:text-white mr-3"
                                      name="material-symbols:design-services"
                                      size="22"/>

                                {{ $t('design_templates') }}
                              </button>
                            </li>-->
            </ul>
          </li>

          <li>
            <div class="text-xs leading-6 text-gray-400">
              Extras
            </div>

            <ul class="-mx-2 mt-2 space-y-1" role="list">
              <li>
                <nuxt-link class="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex items-center gap-x-3 rounded-md p-2 text-sm"
                           to="billing">
                                    <span
                                        class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg font-bold text-[0.625rem] border text-gray-800 group-hover:border-indigo-600 group-hover:text-indigo-600">
                                        F
                                    </span>

                  {{ $t('billing') }}
                </nuxt-link>
              </li>

              <li>
                <nuxt-link
                    :class="$route.name === 'errors' ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'"
                    class="group flex items-center gap-x-3 rounded-md p-2 text-sm"
                    to="/errors">
                                    <span
                                        class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg font-bold text-[0.625rem] border text-gray-800 group-hover:border-indigo-600 group-hover:text-indigo-600">
                                        E
                                    </span>

                  Errors
                </nuxt-link>
              </li>

              <li>
                <a href="https://blog.celesi.com/" target="_blank"
                           class="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex items-center gap-x-3 rounded-md p-2 text-sm">
                                    <span
                                        class="flex h-6 w-6 shrink-0 items-center justify-center bg-yellow-400 rounded-lg font-bold text-[0.625rem] text-white group-hover:border-indigo-600 group-hover:text-white">
                                        B
                                    </span>

                  {{ $t('blog') }}
                </a>
              </li>
            </ul>
          </li>

          <li class="mt-auto space-y-6">
            <actions-support-button/>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "~/stores/auth";
import {useInfoStore} from "~/stores/info";

const user = computed(() => useAuthStore().user)
const info = computed(() => useInfoStore().info)
const router = useRouter()
const {$bus} = useNuxtApp()
const {t} = useI18n()

function redirect(route) {
  if (user.value.connections_count > 0) {
    return router.push(`/${route}`)
  }

  $bus.$emit('flash', {
    type: 'error',
    title: t('connect_at_least_one_platform'),
  })

  router.push('/connect')
}
</script>
