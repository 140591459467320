<template>
  <div v-if="user"
       class="hidden sm:flex sticky top-0 z-40 h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
    <div class="flex flex-1 gap-x-4 justify-end lg:gap-x-6">
      <!--          <notifications-notify/>-->
<!--      <actions-trial-alert-action/>-->

      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <div class="relative">
          <button v-click-outside="closeDropDown" class="-m-1.5 flex items-center p-1.5"
                  @click="showDropDown = !showDropDown">
            <img v-img-error :src="user.avatar" class="h-8 w-8 rounded-full bg-gray-50 object-cover">

            <div class="hidden lg:flex lg:items-center">
                            <span class="ml-4 text-sm font-semibold leading-6 text-gray-900">
                                {{ user.name }}
                            </span>

              <icon class="ml-2 h-5 w-5 text-gray-400" name="mdi:chevron-down"/>
            </div>
          </button>

          <div v-if="showDropDown"
               class="absolute right-0 z-20 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            <nuxt-link class="block hover:bg-gray-100 px-3 py-1 text-sm leading-6 text-gray-900"
                       to="/profile"
                       @click="closeDropDown">
              {{ $t('general.profile') }}
            </nuxt-link>

            <nuxt-link class="block hover:bg-gray-100 px-3 py-1 text-sm leading-6 text-gray-900"
                       to="/billing"
                       @click="closeDropDown">
              {{ $t('billing') }}
            </nuxt-link>

            <span class="cursor-pointer block hover:bg-gray-100 px-3 py-1 text-sm leading-6 text-gray-900"
                  @click="logout">
                            {{ $t('general.logout') }}
                        </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "~/stores/auth";

const route = useRoute()
const router = useRouter()
const {$global} = useNuxtApp()

const store = useAuthStore()
let user = computed(() => store.user)

const showDropDown = ref(false)

function closeDropDown() {
  showDropDown.value = false
}

function logout() {
  $fetch(`${$global.api_url}/api/auth/logout`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + useCookie('token').value
    },
  }).then(async () => {
    await router.push('/')

    window.location.reload()
  })
}

watch(route, () => closeDropDown())
</script>
