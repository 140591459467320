import {defineStore} from "pinia";

export const useInfoStore = defineStore("info", {
    state: () => ({
        info: null,
    }),

    actions: {
        setInfo(payload) {
            this.info = payload
        }
    }
})